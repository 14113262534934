import JoditEditor from "../Jodit/Jodit";

export default function Editor() {
    return (
        <>
            <JoditEditor
            />

        </>
    )
}